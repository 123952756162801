import React, { useState } from "react";
import Chart from "react-apexcharts";
import { getShortNameForProjects,getShortNameForTask } from "../utensils/simplifier";
import {
  Button,
  Container,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

import { styled } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchDataByDateRange } from "../api/api1";

const StyledContainer = styled(Container)({
  backgroundColor: "#FFFFFF",
  padding: "30px",
  borderRadius: "16px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
});

const NavBar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#f5f5f5",
  padding: "15px 30px",
  borderRadius: "8px",
  marginBottom: "20px",
});

const Heading = styled("h1")({
  textAlign: "center",
  color: "#333",
  marginBottom: "30px",
  fontSize: "2rem",
  fontFamily: "Roboto, sans-serif",
});

const SubHeading = styled("h2")({
  textAlign: "left",
  color: "#333",
  marginBottom: "10px",
  fontSize: "1.5rem",
  fontFamily: "Roboto, sans-serif",
});

const ChartContainer = styled(Paper)({
  marginTop: "20px",
  padding: "20px",
  borderRadius: "12px",
  backgroundColor: "#f8f9fa",
  textAlign: "center",
});

const StyledLine = styled("hr")({
  border: "none",
  borderTop: "2px solid #ccc",
  margin: "20px 0",
});

const OverallAnalysis = () => {
  const [projectData, setProjectData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [viewType, setViewType] = useState("day");
  const [loading, setLoading] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);

  const handleViewChange = (event) => {
    setViewType(event.target.value);
    setStartDate(null); // Reset the date selection when view type changes
    setShowGraphs(false);
  };

  const handleSubmit = async () => {
    if (!startDate) return; // Ensure startDate is selected before proceeding
    let minDate, maxDate;
    if (viewType === "day") {
      minDate = startDate.toDateString();
      maxDate = startDate.toDateString();
    } else if (viewType === "week") {
      const startOfWeek = new Date(startDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      minDate = startOfWeek.toDateString();
      maxDate = endOfWeek.toDateString();
    } else if (viewType === "month") {
      const startOfMonth = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      );
      minDate = startOfMonth.toDateString();
      maxDate = endOfMonth.toDateString();
    }
    setLoading(true);
    try {
      const data = await fetchDataByDateRange(minDate, maxDate);
      if (data) {
        const projectDistribution = {};
        const taskDistribution = {};
        Object.keys(data).forEach((projectId) => {
          const project = data[projectId];
          const projectName = project.projectName;
          if (!projectDistribution[projectName]) {
            projectDistribution[projectName] = 0;
          }
          Object.keys(project.users).forEach((userId) => {
            const user = project.users[userId];
            Object.keys(user.hours).forEach((taskId) => {
              const task = user.hours[taskId];
              const taskName = task.name;
              const hours = task.hour;
              projectDistribution[projectName] += hours;
              if (!taskDistribution[taskName]) {
                taskDistribution[taskName] = 0;
              }
              taskDistribution[taskName] += hours;
            });
          });
        });
        setProjectData(
          Object.entries(projectDistribution).filter(
            ([name, hours]) => name && !isNaN(hours)
          )
        );
        setTaskData(
          Object.entries(taskDistribution).filter(
            ([name, hours]) => name && !isNaN(hours)
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
    setShowGraphs(true);
  };

  const handleExport = () => {
    // Helper function to escape commas in CSV fields
    const escapeCommas = (value) => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value}"`; // Enclose the value with double quotes if it contains commas
      }
      return value;
    };
  
    // Prepare the CSV with sections
    const overallAnalysisHeading = "Overall Analysis Report\n\n";
  
    // Add Project Data Section with a header
    const projectSectionHeader = "Projects\nName,Hours\n";
    const projectCsv = projectData
      .map(([name, hours]) => `${escapeCommas(name)},${hours}`)
      .join("\n");
  
    // Add a separator and Task Data Section with a header
    const taskSectionHeader = "\n\nTasks\nName,Hours\n";
    const taskCsv = taskData
      .map(([name, hours]) => `${escapeCommas(name)},${hours}`)
      .join("\n");
  
    // Combine the CSV content
    const csvContent =
      overallAnalysisHeading +
      projectSectionHeader +
      projectCsv +
      taskSectionHeader +
      taskCsv;
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a download link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "timesheet_analysis.csv");
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  return (
    <StyledContainer>
      <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
        <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          OverallAnalysis
        </span>
        Report
      </h1>
      <NavBar style={{ padding: '20px', backgroundColor: '#f0f4f8', borderRadius: '8px' }}>
  <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
    {/* View Type Dropdown */}
    <FormControl variant="outlined" style={{ minWidth: 150 }}>
      <InputLabel>View Type</InputLabel>
      <Select
        value={viewType}
        onChange={handleViewChange}
        label="View Type"
      >
        <MenuItem value="day">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
      </Select>
    </FormControl>

    {/* Date Picker */}
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={
          viewType === "week"
            ? "Select Week"
            : viewType === "month"
            ? "Select Month"
            : "Select Day"
        }
        value={startDate}
        onChange={(newValue) => setStartDate(newValue)}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>

    {/* Buttons aligned to the right */}
    <div className="ml-auto flex space-x-4">
      {/* Submit Button */}
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        style={{
          backgroundColor: '#007bff', // Cool blue button color
          padding: '10px 20px',
          fontWeight: 'bold',
        }}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Submit"
        )}
      </Button>

      {/* Export CSV Button */}
      <Button
        onClick={handleExport}
        variant="contained"
        color="secondary"
        style={{
          padding: '10px 20px',
          fontWeight: 'bold',
          backgroundColor: '#ff4081', // Bright pink for contrast
        }}
      >
        Export CSV
      </Button>
    </div>
  </div>
</NavBar>

      <StyledLine />
      {showGraphs && (
  <>
    <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
  Project Distribution
</h1>
    <ChartContainer style={{ maxWidth: "800px", margin: "0 auto" }}> {/* Adjusted width for balance */}
      <Chart
        options={{
          chart: {
            type: "donut",
            width: "100%", // Increased width for better balance
            height: "40vh", // Adjusted height for balance
          },
          labels: projectData.map((d) => {
            const shortName = getShortNameForProjects(d[0] || "Unknown Project");
            return shortName;
          }),
          colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: [
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#008FFB",
              ],
              stops: [0, 100],
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "14px",
            itemMargin: { horizontal: 10, vertical: 5 },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%", // Increased donut size for better balance
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: "Total",
                    formatter: () =>
                      projectData
                        .reduce((sum, val) => sum + val[1], 0)
                        .toFixed(2),
                  },
                },
              },
            },
          },
        }}
        series={projectData.map((d) => d[1] || 0)}
        type="donut"
      />
    </ChartContainer>

    <h1 className="flex justify-center items-center text-5xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text my-5">
  Task Distribution
</h1>
    <ChartContainer style={{ maxWidth: "800px", margin: "0 auto" }}> {/* Adjusted width for balance */}
      <Chart
        options={{
          chart: {
            type: "donut",
            width: "85%", // Increased width for better balance
            height: "40vh", // Adjusted height for balance
          },
          labels: taskData.map((d) => {
            const shortName = getShortNameForTask(d[0] || "Unknown Task");
            return shortName;
          }),
          colors: ["#775DD0", "#008FFB", "#00E396", "#FEB019", "#FF4560"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: [
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#008FFB",
              ],
              stops: [0, 100],
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontSize: "14px",
            itemMargin: { horizontal: 10, vertical: 5 },
          },
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%", // Increased donut size for better balance
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: "Total",
                    formatter: () =>
                      taskData
                        .reduce((sum, val) => sum + val[1], 0)
                        .toFixed(2),
                  },
                },
              },
            },
          },
        }}
        series={taskData.map((d) => d[1] || 0)}
        type="donut"
      />
    </ChartContainer>
  </>
)}


    </StyledContainer>
  );
};

export default OverallAnalysis;
