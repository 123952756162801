import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";
import Navbar from "./components/Navbar";
import TimesheetDashboard from "./components/TimesheetDashboard";
// import MoreInsights from './components/MoreInsights';
import OverallAnalysis from "./components/OverallAnalysis";
import Dashboard from "./components/Dashboard";
import TimeOffComponent from "./components/TimeOffComponent";
import ProjectAnalysis from "./components/ProjectAnalysis";
import { Navigate } from "react-router-dom";

// Path to your local CSV file


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="dashboard" element={<Dashboard />}>
          <Route
            path="/dashboard/overall-analysis"
            element={<OverallAnalysis />}
          />
          <Route
            exact={true}
            path="/dashboard/user-analysis"
            element={<TimesheetDashboard  />}
          />
          <Route
            path="/dashboard/project-analysis"
            element={<ProjectAnalysis/>}
          />

          <Route
            exact={true}
            path="/dashboard/timeOff"
            element={<TimeOffComponent />}
          />
        </Route>

        <Route 
          path="/" 
          element={<Navigate to="/dashboard/user-analysis" replace />} 
        />
      </Routes>
    </Router>
  );
};

export default App;
