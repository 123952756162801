import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  Bars3Icon,
  XMarkIcon,
  UserIcon,
  ChartBarIcon,
  CalendarIcon,
  DocumentChartBarIcon,
  CogIcon, // Adding a new unique icon for Overall Analysis
} from "@heroicons/react/24/outline"; // Heroicons v2

function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative min-h-screen">
      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full bg-blue-600 p-4 flex flex-col items-center transition-transform duration-[1000ms] ease-in-out text-white shadow-lg z-50 ${
          isOpen ? "w-64" : "w-20"
        }`}
      >
        {/* Toggle button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-white focus:outline-none mb-6"
        >
          {isOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>

        {/* Sidebar Header */}
        <h1
          className={`text-xl font-extrabold mb-8 transition-opacity duration-[1000ms] ease-in-out bg-gradient-to-r from-white via-gray-300 to-blue-300 inline-block text-transparent bg-clip-text ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          What's Up 2.0
        </h1>

        {/* Navigation Links */}
        <nav className="space-y-4">
          <Link
            to="/dashboard/project-analysis"
            className="flex items-center space-x-4 hover:bg-blue-700 p-3 rounded-md transition-all duration-[1000ms] ease-in-out"
          >
            <ChartBarIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-[700ms]">Project Analysis</span>
            )}
          </Link>

          <Link
            to="/dashboard/user-analysis"
            className="flex items-center space-x-4 hover:bg-blue-700 p-3 rounded-md transition-all duration-[1000ms] ease-in-out"
          >
            <UserIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-[700ms]">User Analysis</span>
            )}
          </Link>

          <Link
            to="/dashboard/overall-analysis"
            className="flex items-center space-x-4 hover:bg-blue-700 p-3 rounded-md transition-all duration-[700ms] ease-in-out"
          >
            <CogIcon className="h-6 w-6" /> {/* Updated to a unique icon */}
            {isOpen && (
              <span className="transition-opacity duration-[1000ms]">Overall Analysis</span>
            )}
          </Link>

          {/* <Link
            to="/dashboard/timeOff"
            className="flex items-center space-x-4 hover:bg-blue-700 p-3 rounded-md transition-all duration-[1000ms] ease-in-out"
          >
            <CalendarIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-[1000ms]">Time Offs</span>
            )}
          </Link> */}

          {/* <Link
            to="/dashboard/reports"
            className="flex items-center space-x-4 hover:bg-blue-700 p-3 rounded-md transition-all duration-[700ms] ease-in-out"
          >
            <DocumentChartBarIcon className="h-6 w-6" />
            {isOpen && (
              <span className="transition-opacity duration-[1000ms]">Reports</span>
            )}
          </Link> */}
        </nav>
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 p-6 transition-all duration-[1000ms] ease-in-out ${
          isOpen ? "ml-64" : "ml-20"
        } bg-gray-100 min-h-screen`}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
