import React, { useState, useEffect } from "react";
import { FormControlLabel } from '@mui/material';
import { saveAs } from 'file-saver';
import '../components/TaskViewTable.css'; 
import {
  Button,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  fetchProjects, // Renamed from fetchActiveUsers
  fetchProjectData,
} from "../api/api1"; // Ensure this function fetches projects correctly
import { getShortNameForProjects, getShortNameForTask } from "../utensils/simplifier"; // Import the simplifier functions

const StyledContainer = styled(Container)({
  backgroundColor: "#FFFFFF",
  padding: "30px",
  borderRadius: "16px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  maxWidth: "100%",
});
const Title = styled("h1")({
  textAlign: "center",
  color: "#0d47a1",
  marginBottom: "30px",
  fontSize: "2.5rem",
  fontFamily: "Roboto, sans-serif",
});
const UploadSection = styled("div")({
  marginBottom: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});
const ChartContainer = styled(Paper)({
  marginTop: "20px",
  padding: "20px",
  borderRadius: "12px",
  backgroundColor: "#f8f9fa",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const Navbar = styled('div')(({ scrollPosition }) => ({
  position: scrollPosition > 0 ? 'absolute' : 'relative',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFFFFF',
  padding: '20px',
  transition: 'all 0.3s ease',
  opacity: scrollPosition > 0 ? 1 : 0,
  zIndex: 10,
  boxShadow: scrollPosition > 0 ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A6D854"];

const ProjectAnalysis = () => {
  const [timesheetData, setTimesheetData] = useState({});
  const [selectedProject, setSelectedProject] = useState(null); // Renamed from selectedUser
  const [activeProjects, setActiveProjects] = useState([]); // Renamed from activeUsers
  const [selectedView, setSelectedView] = useState('project'); // state to track the current view
  const [selectedOption, setSelectedOption] = useState('day');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false); // To handle loading state
  const [error, setError] = useState(null); // To handle errors

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const projects = await fetchProjects(); // Ensure this fetches projects correctly
        setActiveProjects(projects);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects.");
      }
    };
    fetchProjectsData();
  }, []);

  useEffect(() => {
    let newStartDate = startDate; // Use a local variable for calculation
    let newEndDate = startDate; // Initialize newEndDate based on startDate

    // Adjust date range based on selectedOption
    if (selectedOption === 'day') {
      // For a single day, min and max dates are the same
      newEndDate = newStartDate;
    } else if (selectedOption === 'week') {
      // For a week, calculate the start and end of the week
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - newStartDate.getDay()); // Start of the week (Sunday)
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newEndDate.getDate() + 6); // End of the week (Saturday)
    } else if (selectedOption === 'month') {
      // For a month, calculate the start and end of the month
      newStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // Start of the month
      newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0); // End of the month
    }

    // Only update the state if necessary to avoid re-triggering the effect
    if (newStartDate.getTime() !== startDate.getTime()) {
      setStartDate(newStartDate); // Update startDate only if it's different
    }
    if (newEndDate.getTime() !== endDate?.getTime()) {
      setEndDate(newEndDate); // Update endDate only if it's different
    }
  }, [selectedOption, startDate, endDate]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const fetchData = async () => {
    if (selectedProject && startDate && endDate) {
      setLoading(true);
      setError(null);
      try {
        // Format the dates as required by your API
        const min_date = startDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        const max_date = endDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

        console.log('Fetching data for:', { selectedProject, min_date, max_date });

        // Use the formatted dates in your API call
        const data = await fetchProjectData(selectedProject, min_date, max_date);

        // Update the state with the fetched data
        setTimesheetData(data);
      } catch (err) {
        console.error("Error fetching timesheet data:", err);
        setError("Failed to fetch timesheet data.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleProjectChange = (event) => { // Renamed from handleUserChange
    setSelectedProject(event.target.value);
  };

  const handleDateChange = (date, isStartDate) => {
    if (isStartDate) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  
  const handleExportCSV = (isMonthSelected) => {
    if (!selectedProject || !Object.keys(timesheetData).length) return;
  
    const project = timesheetData[selectedProject];
    
    if (!project) return;
  
    // Initialize CSV content
    let csvContent = `Project Name: ${project.projectName}\n`;
  
    // Extract tasks and users
    const users = project.users;
    const tasksMap = {};
    const userNames = [];
    
    // Collect task names and initialize task map
    Object.values(users).forEach(user => {
      Object.values(user.hours).forEach(task => {
        tasksMap[task.name] = true;
      });
    });
  
    const taskNames = Object.keys(tasksMap);
    
    // Add headers to the CSV
    csvContent += `User Name, ${taskNames.join(", ")}\n`;
  
    // Loop through users to populate rows
    Object.values(users).forEach(user => {
      const row = [user.name];
      taskNames.forEach(taskName => {
        // Find the task in the user's hours
        const task = Object.values(user.hours).find(t => t.name === taskName);
        row.push(task ? task.hour : 0);
      });
      csvContent += row.join(", ") + "\n";
    });
  
    // Create Blob with proper CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    
    // Generate the filename
    const fileName = isMonthSelected
      ? `${project.projectName}_month_data.csv`
      : `${project.projectName}_data.csv`;
    
    // Save the file
    saveAs(blob, fileName);
  };
  

  // const taskNameMapping = {
  //   "Development Task A": "Dev A",
  //   "Development Task B": "Dev B",
  //   "QA Task C": "QA C",
  //   // Add other task mappings
  // };
  
  // const getShortNameForTask = (taskName) => taskNameMapping[taskName] || taskName;
  
  const processData = (data) => {
    if (!selectedProject || !data || !Object.keys(data).length)
      return { taskDistribution: [], projectDistribution: [], userDistribution: [] };
  
    const taskDistribution = [];
    const projectDistribution = [];
    const userDistribution = [];
  
    const project = data[selectedProject];
    if (!project) return { taskDistribution: [], projectDistribution: [], userDistribution: [] };
  
    const users = project.users;
  
    Object.values(users).forEach((user) => {
      const userTasks = user.hours;
  
      // Processing task distribution
      Object.values(userTasks).forEach((task) => {
        const shortTaskName = getShortNameForTask(task.name);
        const existingTask = taskDistribution.find((t) => t.name === shortTaskName);
        if (existingTask) {
          existingTask.value += task.hour;
        } else {
          taskDistribution.push({ name: shortTaskName, value: task.hour });
        }
      });
  
      // Processing project distribution
      const totalProjectHours = Object.values(userTasks).reduce(
        (sum, task) => sum + task.hour,
        0
      );
      const shortProjectName = getShortNameForProjects(project.projectName);
      const existingProject = projectDistribution.find((p) => p.name === shortProjectName);
      if (existingProject) {
        existingProject.value += totalProjectHours;
      } else {
        projectDistribution.push({
          name: shortProjectName,
          value: totalProjectHours,
        });
      }
  
      // Processing user distribution with first name only
      const totalUserHours = Object.values(userTasks).reduce(
        (sum, task) => sum + task.hour,
        0
      );
      const firstName = user.name.split(" ")[0]; // Get only the first word of the user's name
      const existingUser = userDistribution.find((u) => u.name === firstName);
      if (existingUser) {
        existingUser.value += totalUserHours;
      } else {
        userDistribution.push({ name: firstName, value: totalUserHours });
      }
    });
  
    return { taskDistribution, projectDistribution, userDistribution };
  };
  
  

//   const processData = (data) => {
//     const userDistribution = [];
  
//     // Process user distribution
//     Object.values(data).forEach((project) => {
//       Object.values(project.users).forEach((user) => {
//         // Calculate total hours worked by the user
//         const totalHours = Object.values(user.hours).reduce(
//           (sum, task) => sum + task.hour,
//           0
//         );
//         // Add user name and total hours to the distribution data
//         userDistribution.push({ name: user.name, value: totalHours });
//       });
//     });
  
//     return { taskDistribution, userDistribution };
//   };
  

const renderCharts = () => {
    if (!selectedProject || !Object.keys(timesheetData).length) return null;
  
    // Extract taskDistribution, projectDistribution, and userDistribution from processData
    const { taskDistribution, projectDistribution, userDistribution } = processData(timesheetData);

    if (
      (!taskDistribution || taskDistribution.length === 0) ||
      (!userDistribution || userDistribution.length === 0)
    ) {
      return (
        <div className="flex justify-center items-center h-64">
          <h2 className="text-xl font-semibold text-gray-500">
            No timesheet data found.
          </h2>
        </div>
      );
    }
  
    if (
      taskDistribution.length === 0 &&
      projectDistribution.length === 0 &&
      userDistribution.length === 0
    ) {
      return <p>No data available for the selected project and date range.</p>;
    }
  
    return (
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/2">
        <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text">
  Task Distribution
</h1>
  
          <ChartContainer>
            <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={taskDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={200}
                  innerRadius={100}
                  paddingAngle={5}
                  label={false}
                >
                  {taskDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${value} hours`} />
  
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                  iconType="circle"
                />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
  
        <div className="w-full md:w-1/2">
        <h1 className="flex justify-center items-center text-4xl font-bold bg-gradient-to-r from-black via-gray-800 to-gray-500 inline-block text-transparent bg-clip-text">
  User Distribution
</h1>
  
         <ChartContainer>
  <ResponsiveContainer width="100%" height={500}>
    <PieChart>
      <Pie
        data={userDistribution}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={200}
        innerRadius={100}
        paddingAngle={5}
        label={false}
      >
        {userDistribution.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip formatter={(value) => `${value} hours`} />

      <Legend
        verticalAlign="bottom"
        wrapperStyle={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          maxHeight: "100px",  // Limit height of the legend
          overflowY: "scroll", // Enable vertical scrolling
          paddingTop: "10px",  // Add some spacing from the chart
        }}
        iconType="circle"
      />
    </PieChart>
  </ResponsiveContainer>
</ChartContainer>
      </div>
      </div>
    );
  };
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, startDate, endDate]);
  const { taskDistribution, projectDistribution, userDistribution } = processData(timesheetData);
  return (
    <StyledContainer>
    <Title>
      <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
        <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          Project
        </span>{""}
        Analysis
      </h1>
    </Title>
  
    {/* Blue line appears below the title, not overlapping */}
    <div
      style={{
        borderBottom: '2px solid #0d47a1', // Blue line color
        marginTop: '10px', // Added margin to separate the line from the text
        marginBottom: '20px', // Space below the line
        width: '100%', // Ensure full width of the line
      }}
    ></div>
  
    <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
      {/* Date Range Dropdown */}
      <FormControl style={{ width: '100%', maxWidth: '200px' }}>
        {/* <InputLabel id="date-range-label">Select Range</InputLabel> */}
        {/* <FormControlLabel>Email address</FormControlLabel> */}
        <InputLabel  id="data-range-label" >Select Range</InputLabel>

{/* <Select labelId="label-for-appliances" label="Appliances" /> */}
        <Select
          labelId="date-range-label"
          id="date-range"
          value={selectedOption}
          onChange={handleOptionChange}
          label="Select Range"
        >
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="month">Month</MenuItem>
        </Select>
      </FormControl>
  
      {/* Conditionally Render DatePicker */}
      <div style={{ flex: 1 }}>
        {selectedOption === 'month' ? (
          <DatePicker
            views={['year', 'month']}
            label="Select Month"
            value={startDate}
            onChange={(date) => handleDateChange(date, true)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        ) : (
          <DatePicker
            label="Select Date"
            value={startDate}
            onChange={(date) => handleDateChange(date, true)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      </div>
  
      {/* Project Selection */}
      <FormControl style={{ width: '100%', maxWidth: '500px' }}>
        <InputLabel id="project-select-label">Select Project</InputLabel>
        <Select
          labelId="project-select-label"
          className="capitalize"
          value={selectedProject || ""}
          onChange={handleProjectChange}
          label="Select Project"
        >
          {activeProjects.map((project) => (
            <MenuItem key={project.project_id} value={project.project_id}>
              {project.project_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
  
      {/* Fetch Data Button */}
      {/* <Button
        variant="contained"
        color="primary"
        onClick={fetchData}
        disabled={loading || !selectedProject}
      >
        {loading ? "Fetching..." : "Fetch Data"}
      </Button> */}
  
      {/* Export CSV Button */}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleExportCSV}
        disabled={!selectedProject || !Object.keys(timesheetData).length}
      >
        Export CSV
      </Button>
    </div>
  
    {/* Error Message */}
    {error && (
      <div className="mb-4 text-red-500">
        <p>{error}</p>
      </div>
    )}

  
      {/* Render Charts */}
      {renderCharts()}

      {/* View Selection */}
      <div className="mt-8">
        <div className="flex gap-4 mb-4">
          <p
            className={`border-2 rounded-2xl px-6 py-2 cursor-pointer ${
              selectedView === 'project'
                ? 'bg-orange-600 text-white'
                : 'border-orange-600 text-orange-600'
            }`}
            onClick={() => setSelectedView('project')}
          >
            Individual View
          </p>
          {/* <p
            className={`border-2 border-green-500 rounded-2xl px-6 py-2 cursor-pointer ${
              selectedView === 'timeline'
                ? 'bg-green-600 text-white'
                : 'border-green-500 text-green-500'
            }`}
            onClick={() => setSelectedView('timeline')}
          >
            Timeline
          </p> */}
          <p
            className={`border-2 border-red-500 rounded-2xl px-6 py-2 cursor-pointer ${
              selectedView === 'task'
                ? 'bg-red-600 text-white'
                : 'border-red-500 text-red-500'
            }`}
            onClick={() => setSelectedView('task')}
          >
            Task View
          </p>
        </div>

        {/* Conditionally render views based on selectedView */}
        {selectedView === 'project' && <TimeSheetTable data={timesheetData} />}
        {selectedView === 'timeline' && <TimelineComponent data={timesheetData} />}
      
        {selectedView === 'task' && <TaskViewTable data={timesheetData} />}
      </div>
    </StyledContainer>
  );
};

export default ProjectAnalysis;

// TimeSheetTable Component
const TimeSheetTable = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <p>No timesheet data available.</p>;
  }

  return (
    <div className="p-4">
      {Object.keys(data).map((projectId) => {
        const project = data[projectId];
        return (
          <div key={projectId} className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              {project.project_name}
            </h2>
            {Object.keys(project.users).map((userId) => {
              const user = project.users[userId];
              return (
                <div key={userId} className="mb-4">
                  <h3 className="text-lg font-medium mb-2">
                    {user.name}
                  </h3>
                  <table className="min-w-full table-auto border-collapse border border-gray-300 table-fixed">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2 text-left w-3/4">
                          Task Name
                        </th>
                        <th className="border border-gray-300 px-4 py-2 text-left w-1/4">
                          Hours
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(user.hours).map((taskId, index) => {
                        const task = user.hours[taskId];
                        return (
                          <tr
                            key={taskId}
                            className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                          >
                            <td className="border border-gray-300 px-4 py-2">
                              {task.name}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {task.hour}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};


// TaskViewTable Component

const TaskViewTable = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <p>No task data available.</p>;
  }

  const tasksMap = {};
  const userHoursMap = {};

  const projectIds = Object.keys(data);
  if (projectIds.length === 0) return null;

  const project = data[projectIds[0]]; 
  const users = project.users;

  Object.keys(users).forEach((userId) => {
    const user = users[userId];
    Object.keys(user.hours).forEach((taskId) => {
      const task = user.hours[taskId];
      const shortTaskName = getShortNameForTask(task.name); // Use the imported function here
      if (tasksMap[taskId]) {
        tasksMap[taskId].hours += task.hour;
      } else {
        tasksMap[taskId] = { name: shortTaskName, fullName: task.name, hours: task.hour }; // Store full name
      }

      if (!userHoursMap[userId]) {
        userHoursMap[userId] = { name: user.name, tasks: {}, total: 0 };
      }
      userHoursMap[userId].tasks[taskId] = (userHoursMap[userId].tasks[taskId] || 0) + task.hour;
      userHoursMap[userId].total += task.hour;
    });
  });

  const taskIds = Object.keys(tasksMap);
  const taskNames = taskIds.map((taskId) => tasksMap[taskId]);

  return (
    <div className="p-4 overflow-x">
      <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
            <th className="border border-gray-300 px-4 py-2 text-left">Total</th>
            {taskNames.map((task, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2 text-left">
                <div className="tooltip">
                  {task.name}
                  <span className="tooltiptext">{task.fullName}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(userHoursMap).map((userId) => {
            const user = userHoursMap[userId];
            return (
              <tr key={userId} className="bg-white odd:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{user.name}</td>
                <td className="border border-gray-300 px-4 py-2">{user.total}</td>
                {taskIds.map((taskId) => (
                  <td key={taskId} className="border border-gray-300 px-4 py-2">
                    {user.tasks[taskId] || 0}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// Placeholder for TimelineComponent
const TimelineComponent = ({ data }) => {
  // Implement your timeline view here
  return (
    <div className="p-4">
      <p>Timeline view is under construction.</p>
    </div>
  );
};
